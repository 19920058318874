import { defineStore } from 'pinia'
import router from '../router'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    logged: false,
    ID: 0,
    name: "",
    token: null
  }),

  actions: {
    async login(login,pass){
      var data = await fetch("login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login: login,
          pass: pass,
        }),
      });
      var dataJson = await data.json();
      console.log(dataJson);
      if(dataJson.err==='ok'){
        this.logged=true
        this.ID=dataJson.ID
        this.name=dataJson.name
        this.token=dataJson.token
        router.push('/account');
        return true
      }else{
        this.logged=false
        return false
      }
    },

    async refreshToken(){
      var data = await fetch("refreshToken.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      });
      var dataJson = await data.json();
      console.log(dataJson);
      if(dataJson.err==='ok'){
        this.logged=true
        this.ID=dataJson.ID
        this.name=dataJson.name
        this.token=dataJson.token
        router.push('/account');
        return true
      }else{
        this.logged=false
        router.push('/login');
        return false
      }
    },

    async logout() {
      await fetch("logout.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: this.id,
          token: this.token
        }),
      });

      this.logged = false
      this.id = null
      this.name = ""
      this.mail = ""
      this.token = null
      router.push('/login');
    }
}
})
