<template>
  <div class="contentWrapper">
    <div class="content">
      <div class="login grid">
          <div class="title">
              <p>Prihlásenie</p>
          </div>
          <div class="sekcia">
              <form class="vue-form" @submit.prevent="odoslat">
                  <div v-show="messageErr!=''" class="error-message">
                      <p>{{messageErr}}</p>
                  </div>

                  <div>
                      <label class="label" for="login">Login</label>
                      <input type="text" name="login" id="login" required="" v-model="login">
                  </div>
                  <div>
                      <label class="label" for="pass">Heslo</label>
                      <input type="password" name="pass" id="pass" required="" v-model="pass">
                  </div>
                  <div>
                      <input type="submit" value="Odoslať">
                  </div>

              </form>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores';

export default {
  name: 'LoginView',
  data(){
    return {
      login: "",
      pass: "",
      messageErr: "",   
      authStore: useAuthStore()
    }
  },
  methods: {
    async odoslat(){
      
      var loggedOK=await this.authStore.login(this.login,this.pass);

      
      if(loggedOK==false){
        this.messageErr='Chybné prihlasovacie údaje';
        setTimeout(() => {
          this.messageErr="";
        }, 3000);
      }else{
        this.meno="";
        this.email="";
        this.messageErr="";
      }
      
    }
  }
}
</script>

<style>
.login{
    padding: 0 15px;
}

.login .title{
    text-transform: uppercase;
    grid-column: span 12;
    margin: 0 calc(1vw + 5px);
    margin-top: 22px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 1.5em;
    font-weight: 700;
    border-bottom: 3px solid var(--color1);
    align-items: center;
}

.login .title p{
    margin: 0;
    display: inline-block;
    padding-bottom: 5px;
}

.login .sekcia{
    grid-column: span 12;
    margin: 0 calc(1vw + 10px);
    margin-bottom: 40px;
    padding: 0 ;
}

.login .sekcia p{
    grid-column: span 12;
}

.login .sekcia a.bbo{
    grid-column: span 12;
    width: 100%;
    margin: 10px 0;
}

.login .sekcia a.bbo img{
    width: 100%;
}







.vue-form div {
  position: relative;
  margin: 20px 0;
}

.vue-form .label {
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
}

.vue-form input,
.vue-form textarea,
.vue-form label {
  color: var(--textColor3);
}

.vue-form input[type="text"],
.vue-form input[type="password"],
.vue-form textarea{
  display: block;
  width: calc(100% - 24px);
  appearance: none;
  padding: 12px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  font-family: inherit;
  font-weight: 300;
}

.vue-form input[type="text"]:focus,
.vue-form input[type="password"]:focus,
.vue-form textarea:focus{
  outline: none;
  border-color: var(--color1);
}

.vue-form textarea {
  min-height: 120px;
  resize: none;
  overflow: auto;
}

.vue-form input[type="submit"] {
  border: none;
  background: var(--color1);
  border-radius: 0.25em;
  padding: 12px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  appearance: none;
  font-family: inherit;
  font-size: 1em;
}


.vue-form .error-message {
  height: 35px;
  margin: 0px;
}

.vue-form .error-message p {
  background: #dd4632;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25em;
  padding: 16px;
}

.vue-form .ok-message p {
  background: #20881dbe;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25em;
  padding: 16px;
}

.vue-form .error {
  border-color: #e94b35 !important;
}




@media screen and (min-width: 620px) {
    .info .sekcia p.bbo{
        grid-column: span 8 !important; 
        margin: 10px 0;
    }

    .info .sekcia a.bbo{
        grid-column: span 4 !important; 
        justify-self: right;
        width: 90%;
        margin: 10px;
    }
}
</style>