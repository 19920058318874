import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AccountView from '@/views/AccountView.vue'
import LoginView from '@/views/LoginView.vue';
import { useAuthStore } from '@/stores';

const routes = [
  
  {
    path: '/account',
    name: 'Account',
    component: AccountView
  },{
    path: '/login',
    name: 'Login',
    component: LoginView
  },{
    path: '/',
    name: 'Home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/','/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.logged) {
      return '/';
  }
});
