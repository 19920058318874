<template>
  <div id="progressBarContainer">
    <div id="progressBar"></div>
  </div>

  <div class="mainContainer">
    <div class="header" id="headerSwipe">
      <div class="headerBG">
        <div class="BG_image" id="BG_image_x"></div>
        <div
          v-for="(image, index) in bgImages"
          :key="index"
          :id="'BG_image_' + index"
          class="BG_image"
          :class="{
            bgImageActual: index === bgImageActual,
            bgImagePrev: index === bgImagePrev,
          }"
          :style="{ backgroundImage: 'url(' + image + ')' }"
        ></div>
      </div>
      <Header/>
    </div>
    <RouterView/>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Moment from "moment";
import MomentTimeZone from "moment-timezone";

import { Chart, registerables, Interaction } from "chart.js";
import "chartjs-adapter-date-fns";
import Highcharts from "highcharts";

import { useAuthStore } from '@/stores';

Chart.register(...registerables);

Chart.defaults.font.family = "'Roboto Slab', serif";
Chart.defaults.font.weight = 300;

Highcharts.setOptions({
  lang: {
    decimalPoint: ",",
    months: [
      "Január",
      "Február",
      "Marec",
      "Apríl",
      "Máj",
      "Jún",
      "Júl",
      "August",
      "September",
      "Október",
      "November",
      "December",
    ],
    shortMonths: [
      "Jan.",
      "Feb.",
      "Mar.",
      "Apr.",
      "Máj",
      "Jún",
      "Júl",
      "Aug.",
      "Sep.",
      "Okt.",
      "Nov.",
      "Dec.",
    ],
    weekdays: [
      "Nedeľa",
      "Pondelok",
      "Utorok",
      "Streda",
      "Štvrtok",
      "Piatok",
      "Sobota",
    ],
  },
  chart: {
    type: "spline",
    style: {
      fontFamily: "'Roboto Slab', serif",
      fontWeight: 300,
    },
    marginLeft: 5,
    marginRight: 5,
    zoomType: "x"
  },
  tooltip: {
    backgroundColor: "rgba(0,0,0,0.8)",
    borderWidth: 0,
    borderRadius: 0,
    shadow: false,
    style: {
      color: "#fff",
      fontSize: "1em",
    },
    dateTimeLabelFormats: {
      day: "%A, %e. %b %Y, %H:%M",
      hour: "%A, %e. %b, %H:%M",
      millisecond: "%H:%M:%S.%L",
      minute: "%A, %e. %b, %H:%M",
      month: "%B %Y",
      second: "%A, %e. %b, %H:%M",
      week: "Týždeň od %e. %b %Y",
      year: "%Y",
    },
    shared: true,
    positioner: (labelWidth, labelHeight, point) => {
      var x = 0;
      var y = 0;
      if (point.plotX - labelWidth / 2 < 0) x = point.plotX + 8;
      else if (point.plotX - labelWidth < 0) x = point.plotX - labelWidth / 2;
      else x = point.plotX - labelWidth;
      if (point.plotY - labelHeight < 0) y = 0;
      else y = point.plotY - labelHeight;

      return { x: Math.round(x), y: y };
    },
  },
  title: {
    text: "",
  },
  plotOptions: {
    series: {
      color: "rgb(0,129,197)",
      turboThreshold: 10000,
      states: {
        select: {
          enabled: true,
        },
        hover: {
          lineWidthPlus: 0,
        },
      },
      marker: {
        symbol: "circle",
      },
    },
    spline: {
      lineWidth: 1,
      marker: {
        radius: 0,
      },
    },
  },
  time: {
    moment: Moment,
    // timezone: "Europe/Prague"
    useUTC: false,
  },
  xAxis: {
    gridLineColor: "#F3F3F3",
    gridLineDashStyle: "Dash",
    gridLineWidth: 1,
    crosshair: {
      color: "var(--color2)",
    },
    tickPixelInterval: 100,
    startOnTick: false,
    labels: {
      autoRotationLimit: 0,
    },
    lineColor: "#F0F0F0",
    tickColor: "#F0F0F0",
  },
  yAxis: {
    allowDecimals: false,
    alignTicks: true,
    gridLineColor: "#F3F3F3",
    gridLineWidth: 1,
    tickPixelInterval: 50,
    title: {
      align: "high",
      rotation: 0,
      offset: 20,
      y: -15,
      style: {
        fontWeight: 500,
      },
      text: "",
      textAlign: "center",
    },
    labels: {
      style: {
        fontWeight: 500,
      },
      x: 0,
      y: -2,
    },
  },
  responsive: {
    rules: [
      {
        chartOptions: {
          xAxis: {
            tickPixelInterval: 70,
          },
        },
        condition: {
          maxWidth: 600,
        },
      },
    ],
  },
});

export default {
  components: { Header },

  data() {
    return {
      bgImages: [],
      bgImageActual: 0,
      bgImagePrev: null,
      bgImageTimer: null,
      bgImageDurr: 20000,   
      authStore: useAuthStore()
    };
  },

  computed: {

  },

  watch: {
    
  },

  methods: {
    async getImages() {
      const data = await fetch(
        "PHP/loadBG.php?lokalita=0"
      );
      const dataJson = await data.json();
      const randomData = dataJson["obrazky"].sort(() => Math.random() - 0.5);
      //console.log(randomData);
      this.bgImages = [];
      if (this.bgImageTimer != null) clearInterval(this.bgImageTimer);
      randomData.forEach((obrazok, index) => {
        var img = new Image();
        img.onload = (e) => {
          this.bgImages.push(e.target.getAttribute("src"));
          if (index == randomData.length - 1) {
            $("#BG_image_x").fadeOut(2000);
            this.bgImageTimer = setInterval(() => {
              this.rotateImage();
            }, this.bgImageDurr);
          }
        };
        img.src = require("./assets/BG/" + obrazok);
      });
    },

    rotateImage() {
      const count = this.bgImages.length;
      if (count == 0) return;

      this.bgImagePrev = this.bgImageActual;

      var next;
      if (this.bgImageActual == count - 1) next = 0;
      else next = this.bgImageActual + 1;

      const nextImg = $("#BG_image_" + next);
      const currentImg = $("#BG_image" + this.bgImageActual);

      nextImg.fadeOut(0);
      this.bgImagePrev = this.bgImageActual;
      this.bgImageActual = next;
      nextImg.fadeIn(5000);
    },
    
  },

  created() {
    this.getImages();
    this.authStore.refreshToken();
    
  },

  mounted() {

  }
};
</script>

<style>
/*         LOADER          */
.loader {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.loader img {
  height: 1.5em;
  align-self: center;
  margin: 3px;
}

.loader .loaderItem {
  align-self: center;
  margin: 3px;
  font-size: 1.2em;
  font-weight: 500;
}

.offline{
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  background-color: rgba(213, 90, 90,0.8);
  z-index: 1000;
  color: white;
  font-size: 0.6em;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}

@supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
  .help{
    background-color: rgba(0,0,0,0.6);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
}

.infoBoxItems{
  max-width: 960px;
  margin: 0 auto;
  color: var(--textColor3);
}

.infoBox{
  margin: 10px;
  display: flex;
}

.infoBoxBar{
  width: 10px;
  display: inline-block;
}

.infoBoxContentWrapper{
  width: 100%;
}

.infoBoxContent{
  padding: 10px;
  border: 1px solid;
  border-left: none !important;
  border-color: rgba(220, 220, 220);
  background-color: rgba(250, 250, 250);
}

.infoBoxTitle{
  grid-column: 2/12;
  justify-self: center;
  font-weight: 500;
  font-size: 1.1em;
}

.infoBox .closeImg{
  width: 10px;
  grid-column: 12/13;
  justify-self: end;
  cursor: pointer;
}

.infoBoxText{
  grid-column: 2/12;
  justify-self: center;
  margin-top: 10px;
  font-size: 0.9em;
}

.color1{
  background-color: rgb(91, 161, 91);
}
.color2{
  background-color: rgb(211, 164, 67);
}
.color3{
  background-color: rgb(213, 90, 90);
}

#rendered-items-flexbox {
  display: flex;
  justify-content: center;
  min-height: fit-content;
  width: 100%;
  box-sizing: border-box;
  touch-action: pan-y;
}

.transition-initial {
  transition: transform 0s ease;
}

.transition-item {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.rendered-item {
  height: 100%;
  overflow: hidden;
  min-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.item-content {
  height: 100%;
  width: 100vw;
  margin: 0 auto;
  box-sizing: border-box;
}

:root {
  --textColor1: rgb(32, 32, 32);
  --textColor3: rgb(63, 63, 63);
  --textColor2: rgb(177, 177, 177);
  --color1: rgb(0, 129, 197);
  --color2: rgb(171, 218, 243);
}

html {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: hidden;
  font-family: "Roboto Slab", serif;
  font-size: calc(2vw + 15px);
  font-weight: 300;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--textColor1);
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
}

.mainContainer {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  perspective: 1px;
  perspective-origin: top;
  transform-style: preserve-3d;
  font-size: 62.5%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* margin-top: 8vh; */
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.header {
  /* background: url("./assets/02.jpg") center center; */
  background-size: cover;
  transform: translateZ(-1px) scale(2.02);
  transform-origin: center top;
  height: 40vh;
  min-height: 150px;
  width: 100%;
  z-index: -2;
  color: white;
  transform-style: preserve-3d;
}

.headerBG {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -40;
}

#BG_image_x {
  background: url("./assets/01.jpg") center center;
  z-index: -5 !important;
}

.headerBG .BG_image {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  z-index: -30;
}

.bgImageActual {
  z-index: -10 !important;
}

.bgImagePrev {
  z-index: -20 !important;
}

.contentWrapper {
  position: relative;
  width: 100%;
  background: white;
  box-shadow: 0px -11px 8px -10px #1116;
  -webkit-box-shadow: 0px -11px 8px -10px #1116;
  -moz-box-shadow: 0px -11px 8px -10px #1116;
  z-index: 1;
  padding-top: 10px;
}

.contentMenu {
  padding: 0 10px;
  max-width: 500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(10,1fr);
  font-size: 0.8em;
  font-weight: 500;
  color: var(--textColor2);
}

.menuItem {
  grid-column: span 2;
  justify-self: center;
  /* align-self: center; */
  padding: 15px 0;
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .menuItem:hover {
    /* background-color: var(--color2); */
    border-bottom: 2px solid var(--color2);
  }
}

.contentMenu .selected {
  border-bottom: 2px solid var(--color1);
  color: var(--textColor1);
  font-weight: 900;
}

.content {
  max-width: 960px;
  padding: 10px 0 70px 0;
  margin: 0 auto;
  overflow: hidden;
}

#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: var(--color2);
  width: 0.5%;
}

#progressBar {
  position: absolute;
  z-index: 20;
  background-color: var(--color1);
  width: 100%;
}

#progressBarContainer,
#progressBar {
  top: 0;
  right: 0;
  bottom: 0;
}

@media screen and (min-width: 620px) {
  body {
    font-size: calc(1vw + 15px);
  }
}

@media screen and (min-width: 960px) {
  body {
    font-size: 25px;
  }
}

@media (min-aspect-ratio: 5/4) {
  .header {
    height: 30vh;
  }
}
</style>
