<template>
    <div class="contentWrapper">
      <div class="contentMenu grid">
        <span
          class="menuItem"
          @click="page='grafy'"
          :class="{ selected: page === 'grafy' }"
          >Grafy</span
        >
        <span
          class="menuItem"
          @click="page='infoboxy'"
          :class="{ selected: page === 'infoboxy' }"
          >InfoBoxy</span
        >
        <span
          class="menuItem"
          @click="page='hardware'"
          :class="{ selected: page === 'hardware' }"
          >HardWare</span
        >
        <span
          class="menuItem"
          @click="page='databaza'"
          :class="{ selected: page === 'databaza' }"
          >Databáza</span
        >
        <span
          class="menuItem"
          @click="odhlasit"
          >Odhlásiť</span
        >
      </div>

      <div v-show="infoBoxes.length" class="infoBoxItems">
        <div
          v-for="(infoBox, index) in infoBoxes"
          :key="index"
          class="infoBox"
        >
          <div class="infoBoxBar" 
            :class="{
              color1: infoBox.color === '1',
              color2: infoBox.color === '2',
              color3: infoBox.color === '3',
            }">
          </div>
          <div class="infoBoxContentWrapper">
            <div class="infoBoxContent grid">
              <div class="infoBoxTitle">{{ infoBox.title }}</div>
              <img class="closeImg" src="../assets/ikony/black/close.png" alt="" @click="closeInfoBox(index)" />
              <div class="infoBoxText">{{ infoBox.text }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <Grafy
          @updateHeight="updateHeight"
          @loaded="loaded"
          @updateInterface="getInterface"
          v-if="page === 'grafy'"
        />
        <Infoboxy
          @updateHeight="updateHeight"
          @loaded="loaded"
          @updateInterface="getInterface"
          v-else-if="page === 'infoboxy'"
        />
        <Hardware
          @updateHeight="updateHeight"
          @loaded="loaded"
          @updateInterface="getInterface"
          v-else-if="page === 'hardware'"
        />
        <Databaza
          @updateHeight="updateHeight"
          @loaded="loaded"
          @updateInterface="getInterface"
          v-else-if="page === 'databaza'"
        />
      </div>
    </div>
</template>
<script>
import Grafy from "@/components/Grafy.vue";
import Infoboxy from "@/components/Infoboxy.vue";
import Hardware from "@/components/Hardware.vue";
import Databaza from "@/components/Databaza.vue";
import { useAuthStore } from '@/stores';

export default {
    components: {Grafy, Infoboxy, Hardware, Databaza },

    data(){
      return{
        infoBoxes: [],
        page: 'grafy',
        authStore: useAuthStore()
      }
    },

    methods: {

      odhlasit(){
        this.authStore.logout()
      },

      async getInfoBoxes() {
        const data = await fetch("https://meteobb.sk/PHP/loadInfoBoxes.php?lokalita=" + this.aktualnaLokalita);
        const dataJson = await data.json();

        var infoBoxes=[];
        if(dataJson.dataOffline==1){
          this.dataOffline=dataJson.lastData;
        }else this.dataOffline=null;

        if (this.$cookies.isKey("meteobb_infoBoxes") && dataJson.infoBoxes.length) {
          const cookieBoxes = this.$cookies.get("meteobb_infoBoxes");
          //console.log(cookieBoxes);
          for (const infoBox of dataJson.infoBoxes) {
            var show=1;
            for (const cookieBox of cookieBoxes.id) {
              if (cookieBox == this.aktualnaLokalita+"_"+infoBox.id) {
                show=0;
                break;
              }
            }
            if(show)infoBoxes.push(infoBox);
          }
          
        } else infoBoxes=dataJson.infoBoxes;
        this.infoBoxes=infoBoxes;

      },

      closeInfoBox(index){
        if (this.$cookies.isKey("meteobb_infoBoxes")) {
          const cookieBoxes = this.$cookies.get("meteobb_infoBoxes");
          cookieBoxes.id.push(this.aktualnaLokalita+"_"+this.infoBoxes[index].id);
          this.$cookies.set("meteobb_infoBoxes",cookieBoxes,"10Y");
        }else{
          const cookieBoxes={id:[this.aktualnaLokalita+"_"+this.infoBoxes[index].id]};
          this.$cookies.set("meteobb_infoBoxes",cookieBoxes,"10Y");
        }
        this.getInfoBoxes();
      }
    }
}

</script>