<template>
    <div class="aktualne grid">
        <div class="infoItem suhrn grid">

            <div class="label">
                <img src="../assets/ikony/black/location.png" alt="" class="ikona">
                Aktuálne dáta
            </div>
            <div class="infoSuhrn">
                <div>
                    <span class="dot" :style="{backgroundColor: meteo1color}"></span>
                    Meteo1: {{ aktualneDMeteo1 }}</div>
                <div>
                    <span class="dot" :style="{backgroundColor: meteo2color}"></span>
                    Meteo2: {{ aktualneDMeteo2 }}</div>
            </div>

            <!-- <div class="minimum">{{data.slnkoStr}}: {{data.slnkoTime}}</div>
            <div class="maximum">Fáza mesiaca: {{data.mesiac}}</div> -->

            <div class="slnkoMesiacData">
                <div class="popis">Lokalita</div>
                <select v-model="lokalitaID" name="lokalita" class="rokSelect" id="lokalitaSelect" @change="changeLokalita">
                    <option value="0">Centrum</option>
                    <option value="1">Sásová</option>
                </select>
            </div>

            <div class="slnkoMesiacData right">
                <div class="popis">Rozsah</div>
                <select name="rozsah" v-model="rozsah" class="rokSelect" id="rozsahSelect" @change="changeRozsah">
                    <option value="0">3h</option>
                    <option value="1">6h</option>
                    <option value="2">12h</option>
                    <option value="3">24h</option>
                    <option value="4">48h</option>
                    
                </select>
            </div>

        </div>
        <div class="title">
            <p>Grafy</p>
        </div>
        <div  v-if="data!=null"  class="archiv sekcia grid">
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/teplota.png" alt="" class="ikona">
                    Teplota
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[0]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/vlhkost.png" alt="" class="ikona">
                    Vlhkosť
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[1]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/tlak.png" alt="" class="ikona">
                    Tlak
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[2]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/PM10.png" alt="" class="ikona">
                    Prachové častice
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[12]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/oblacnost.png" alt="" class="ikona">
                    Teplota oblohy
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[3]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/oblacnost.png" alt="" class="ikona">
                    Oblačnosť
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[4]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/gauge.png" alt="" class="ikona">
                    Úhrny zrážok
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[5]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/zrazky.png" alt="" class="ikona">
                    Zrážky
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[6]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/teplota.png" alt="" class="ikona">
                    Rýchlosť vetra
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[8]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/smer.png" alt="" class="ikona">
                    Smer vetra
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[9]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/svetlo.png" alt="" class="ikona">
                    Osvetlenie
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[10]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/svetlo.png" alt="" class="ikona">
                    sln. energia
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[11]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/zrazky.png" alt="" class="ikona">
                    Laser. detektor
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[13]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/zrazky.png" alt="" class="ikona">
                    Kalib. laseru
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[15]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/zrazky.png" alt="" class="ikona">
                    Akust. detektor
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[14]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/zrazky.png" alt="" class="ikona">
                    Kalib. pot.
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[16]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            
        </div>
        <div v-else class="loader">
            <div class="loaderItem">Načítavam údaje</div>
            <Loader class="loaderItem" />
        </div>
        <div class="update">
            <img src="../assets/ikony/white/update.png" alt="" class="ikona">
            Aktualizované: {{ updated }}
        </div>
    </div>
    <!-- <div v-else-if="online" class="loader">
        <div class="loaderItem">Načítavam údaje</div>
        <Loader class="loaderItem" />
    </div>
    <div v-else class="loader">
        <div class="loaderItem">Stanica {{lokalita}} OFFLINE</div>
        <img src="../assets/ikony/black/offline.png"/>
    </div> -->
</template>

<script>

import Loader from "./Loader.vue"
import Highcharts, { syncTimeout } from "highcharts";

export default {
    components: {Loader},
    data(){
        return {
            data: null,
            aktualneDMeteo1: "",
            aktualneDMeteo2: "",
            meteo1color: "#ff8000",
            meteo2color: "#ff8000",
            updated: "",
            lokalitaID:1,
            rozsah:3,
            updateTimer: null,
            chartOptions: [
                {//0 teplota
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}°C"
                            },
                            softMin: 0,
                            softMax: 0
                        }
                    ],
                    series: [
                        {
                            name: "Teplota",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            data: null
                        },
                        {
                            name: "Pocitová teplota",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            color: "rgb(70, 70, 70)",
                            data: null
                        },
                        {
                            name: "Hranica pocitu dusna",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            color: "rgb(210, 210, 210)",
                            data: null
                        }
                    ],
                    legend:{
                        enabled: true
                    },
                    chart:{
                        
                    }
                },{//1 vlhkost
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}%"
                            },
                            min: 0,
                            max: 100
                        }
                    ],
                    series: [
                        {
                            name: "Vlhkosť vzduchu",
                            tooltip: {
                                valueSuffix: " %",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//2 tlak
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}hPa"
                            },
                            softMin: 960,
                            softMax: 1100
                        }
                    ],
                    series: [
                        {
                            name: "Tlak",
                            tooltip: {
                                valueSuffix: " hPa",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//3 teplota oblohy
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}°C"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "Jasná obloha",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            data: null
                        },
                        {
                            name: "Zamračená obloha",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            color: "rgb(80, 80, 80)",
                            data: null
                        },
                        {
                            name: "IR",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            color: "rgb(179, 0, 0)",
                            data: null
                        },
                        {
                            name: "IR1",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            color: "rgb(0, 102, 0)",
                            data: null
                        },
                        {
                            name: "IR2",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            color: "rgb(128, 0, 96)",
                            data: null
                        },
                        {
                            name: "IR3",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            color: "rgb(204, 122, 0)",
                            data: null
                        }
                    ],
                    legend:{
                        enabled: true
                    }
                },{//4 oblacnost
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "right",
                                format: "{text}%",
                                x: -3,
                                y: 3
                            },
                            min: 0,
                            max: 100
                        }
                    ],
                    series: [
                        {
                            name: "Oblačnosť",
                            tooltip: {
                                valueSuffix: " %",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined,
                    }
                },{//5 uhrny zrazok
                    xAxis:{
                        type: "datetime",
                        
                    },
                    yAxis:{
                        labels:{
                            align: "right",
                            format: "{text}mm",
                            x: -8,
                            y: 3
                        },
                        min: 0,
                        softMax: 1
                    },
                    series: [
                        {
                            name: "Úhrny zrážok",
                            type: "column",
                            tooltip: {
                                valueSuffix: " mm",
                                valueDecimals: 1
                            },
                            data: [],
                        }
                    ],
                    legend:{
                        enabled: false
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    }
                },{//6 zrazky
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            y: -5
                        },
                        min: 1,
                        max: 8,
                        type: "category",
                        tickInterval: 1,
                        tickmarkPlacement: "on",
                        categories: ["","Bez zrážok", "Mrholenie", "Slabý dážď", "Dážď", "Silný dážď", "Lejak", "Prívalový dážď", "Sneženie"]
                    },
                    series: [
                        {
                            name: "Zrážky",
                            type: "scatter",
                            marker: {
                                radius: 2
                            },
                            tooltip: {
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ],
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    tooltip:{
                        /*headerFormat: '<span style="font-size: 10px"> {point.x:%A, %e. %b, %H:%M:%S}</span><br/>',
                        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.key}</b><br/>',
                        */
                       formatter: (point)=>{
                            const datum = Highcharts.dateFormat('%A, %e. %b, %H:%M', point.chart.hoverPoint.x);
                            const farba = point.chart.hoverPoint.color;
                            const meno = point.chart.hoverPoint.series.name;
                            const kategoria = point.chart.axes[1].categories[point.chart.hoverPoint.y];
                            return '<span style="font-size: 10px">'+datum+'</span><br/><span style="color:'+farba+'">●</span> '+meno+': <b>'+kategoria+'</b><br/>';
                        }
                    },
                    legend:{
                        enabled: false
                    }
                },{//7 LCL
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}m"
                        }
                    },
                    series: [
                        {
                            name: "Výška oblakov",
                            tooltip: {
                                valueSuffix: " m",
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//8 rychlost vetra
                    xAxis:{
                        type: "datetime",
                        gridLineWidth: 0
                    },
                    legend:{
                        enabled: false
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    yAxis:{
                        labels:{
                            align: "right",
                            format: "{text}m/s",
                            x: -5,
                            y: 3
                        },
                        min: 0,
                        softMax: 10,
                        plotBands: [
                            {
                                from: 0.2,
                                to: 1.5,
                                color: 'hsl(201, 100%, 100%)',
                                label: {
                                    text: 'Vánok',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 1.5,
                                to: 3.3,
                                color: 'hsl(201, 100%, 97%)',
                                label: {
                                    text: 'Slabý vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 3.3,
                                to: 5.4,
                                color: 'hsl(201, 100%, 94%)',
                                label: {
                                    text: 'Mierny vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 5.4,
                                to: 10.7,
                                color: 'hsl(201, 100%, 91%)',
                                label: {
                                    text: 'Čerstvý vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 10.7,
                                to: 13.8,
                                color: 'hsl(201, 100%, 88%)',
                                label: {
                                    text: 'Silný vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 13.8,
                                to: 17.1,
                                color: 'hsl(201, 100%, 85%)',
                                label: {
                                    text: 'Prudký vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 17.1,
                                to: 20.7,
                                color: 'hsl(201, 100%, 82%)',
                                label: {
                                    text: 'Búrlivý vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 20.7,
                                to: 32.6,
                                color: 'hsl(201, 100%, 79%)',
                                label: {
                                    text: 'Víchrica',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 32.6,
                                color: 'hsl(201, 100%, 76%)',
                                label: {
                                    text: 'Orkán',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }
                        ]
                    },
                    series: [
                        {
                            name: "Rýchlosť vetra",
                            tooltip: {
                                valueSuffix: " m/s",
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ]
                },{//9 smer vetra
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            y: -5
                        },
                        min: 1,
                        max: 8,
                        type: "category",
                        tickInterval: 1,
                        tickmarkPlacement: "on",
                        categories: ["","S", "SV", "V", "JV", "J", "JZ", "Z", "SZ"]
                    },
                    series: [
                        {
                            name: "Smer vetra",
                            type: "scatter",
                            marker: {
                                radius: 2
                            },
                            tooltip: {
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ],
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    tooltip:{
                        /*headerFormat: '<span style="font-size: 10px"> {point.x:%A, %e. %b, %H:%M:%S}</span><br/>',
                        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.key}</b><br/>',
                        */
                       formatter: (point)=>{
                            const datum = Highcharts.dateFormat('%A, %e. %b, %H:%M', point.chart.hoverPoint.x);
                            const farba = point.chart.hoverPoint.color;
                            const meno = point.chart.hoverPoint.series.name;
                            const kategoria = point.chart.axes[1].categories[point.chart.hoverPoint.y];
                            return '<span style="font-size: 10px">'+datum+'</span><br/><span style="color:'+farba+'">●</span> '+meno+': <b>'+kategoria+'</b><br/>';
                        }
                    },
                    legend:{
                        enabled: false
                    }
                },{//10 LUX
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}Lx"
                        },
                        min:0,
                        softMax: 100
                    },
                    series: [
                        {
                            name: "Intenzita svetla",
                            tooltip: {
                                valueSuffix: " Lx",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//11 energia
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}W"
                        },
                        min:0,
                        softMax: 100
                    },
                    series: [
                        {
                            name: "Slnečná energia",
                            tooltip: {
                                valueSuffix: " W/m2",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//12 PM25,10
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}μg"
                        },
                        min:0,
                        softMax: 50
                    },
                    series: [
                        {
                            name: "PM2.5",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            data: null
                        },{
                            name: "PM10",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            color: "rgb(210, 210, 210)",
                            data: null
                        }
                    ]
                },{//13 laser
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}"
                            },
                            min: 0,
                            softMax: 10
                        }
                    ],
                    series: [
                        {
                            name: "Laserový detektor",
                            tooltip: {
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//14 akustika
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}"
                            },
                            min: 0,
                            softMax: 10
                        }
                    ],
                    series: [
                        {
                            name: "Akustický detektor",
                            tooltip: {
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//15 kalibracia laseru
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}"
                            },
                            min: 0,
                            softMax: 20
                        }
                    ],
                    series: [
                        {
                            name: "Kalibrácia laseru",
                            tooltip: {
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//16 kalibracia pot
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "POT_Voltage",
                            type: "spline",
                            tooltip: {
                                valueDecimals: 1
                            },
                            data: null
                        },
                        {
                            name: "A_Voltage",
                            type: "spline",
                            tooltip: {
                                valueDecimals: 1
                            },
                            color: "rgb(210, 210, 210)",
                            data: null
                        }
                    ],
                    legend:{
                        enabled: true
                    }
                }
            ],

        }
    },
    mounted(){
        if(this.$cookies.isKey("adminLokalita"))this.lokalitaID=this.$cookies.get("adminLokalita");
        if(this.$cookies.isKey("adminRozsah"))this.rozsah=this.$cookies.get("adminRozsah");
        this.updateMe();
        this.updateTimer = setInterval(() => {
            console.log("TIMER")
            this.updateMe();
        }, 10000);
        console.log("Mounted");
    },
    beforeUnmount(){
        clearInterval(this.updateTimer);
        console.log("beforeUnmount");
    },
    methods:{
        changeLokalita(){
            this.data=null;
            console.log("Lokalita:"+this.lokalitaID);
            this.$cookies.set("adminLokalita",this.lokalitaID);
            this.updateMe()
        },

        changeRozsah(){
            this.data=null;
            console.log("Rozsah:"+this.rozsah);
            this.$cookies.set("adminRozsah",this.rozsah);
            this.updateMe()
        },

        async updateMe(){
            //this.data=null;
            console.log("ACTUAL_LOAD");
            const data = await fetch("loadGrafy.php?lokalita="+this.lokalitaID+"&rozsah="+this.rozsah);
            const dataJson = await data.json(); 
            console.log(dataJson);
            
            this.chartOptions[0].series[0].data=dataJson.TEMP
            this.chartOptions[0].series[1].data=dataJson.RF
            this.chartOptions[0].series[2].data=dataJson.dusno
            this.chartOptions[1].series[0].data=dataJson.RH
            this.chartOptions[2].series[0].data=dataJson.TLAK
            this.chartOptions[3].series[0].data=dataJson.SKY_TEMP
            this.chartOptions[3].series[1].data=dataJson.CLOUD_TEMP
            this.chartOptions[3].series[2].data=dataJson.IR
            this.chartOptions[3].series[3].data=dataJson.IR1
            this.chartOptions[3].series[4].data=dataJson.IR2
            this.chartOptions[3].series[5].data=dataJson.IR3
            this.chartOptions[4].series[0].data=dataJson.CLOUD_P
            this.chartOptions[5].series[0].data=dataJson.GAUGE
            this.chartOptions[6].series[0].data=dataJson.zrazky
            this.chartOptions[8].series[0].data=dataJson.WIND
            this.chartOptions[9].series[0].data=dataJson.WIND_DIR
            this.chartOptions[10].series[0].data=dataJson.AMB
            this.chartOptions[11].series[0].data=dataJson.SUN_RAD
            this.chartOptions[12].series[0].data=dataJson.PM25
            this.chartOptions[12].series[1].data=dataJson.PM10
            this.chartOptions[13].series[0].data=dataJson.LASER
            this.chartOptions[14].series[0].data=dataJson.RAIN_ACOUSTIC
            this.chartOptions[15].series[0].data=dataJson.LASER_VALUE
            this.chartOptions[16].series[0].data=dataJson.POT_VOLTAGE
            this.chartOptions[16].series[1].data=dataJson.A_VOLTAGE

            this.chartOptions[5].series[0].pointRange=dataJson.uhrny_range;

            this.aktualneDMeteo1=dataJson.meteo1;
            this.aktualneDMeteo2=dataJson.meteo2;

            this.meteo1color=dataJson.meteo1color;
            this.meteo2color=dataJson.meteo2color;

            this.updated=dataJson.aktualizacia;

            this.data=1;
        }
    }
}
</script>

<style>
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.archiv .infoItem{
    grid-column: span 12;
    font-weight: 300;
    margin: 0 calc(1vw + 10px);
    padding: 22px 0;
    border-bottom: 1px solid rgb(230, 230, 230);
    align-self: start;
    height: calc(100% - 44px);
}

.archiv .infoItem:last-child{
    border-bottom: none;
}

.archiv .infoItem .ikona{
    height: 1.2em;
    vertical-align: middle;
    padding-right: 3px;
}

.archiv .infoItem .label.archiv{
    grid-column: span 12;
}

.archiv .infoItem .label{
    text-transform: uppercase;
    grid-column: span 7;
    align-self: center;
    font-size: 1.1em;
    font-weight: 500;
}

.archiv .infoItem.small .label{
    grid-column: span 5;
}

.archiv .infoItem.small .data{
    grid-column: span 7;
    font-size: 1.3em;
    line-height: 1.8em;
}

.archiv .infoItem.large .label{
    grid-column: span 8; 
}

.archiv .infoItem.large .data{
    grid-column: span 4;
}

.archiv .graf{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 12;
    overflow: hidden;
    height: calc(150px + 15vh);
    padding-top: 20px;
}

.archiv .highChartsGraf{
    margin-bottom: 0 !important;
}

.loader{
    grid-column: span 12;
}

.aktualne .sekcia{
    grid-column: span 12;
    /* margin-top: 40px; */
    margin-bottom: 40px;
}

.aktualne{
    padding: 0 15px;
}

/*         MAIN ITEMS         */

.aktualne .title{
    text-transform: uppercase;
    grid-column: span 12;
    margin: 0 calc(1vw + 5px);
    margin-top: 22px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 1.5em;
    font-weight: 700;
    border-bottom: 3px solid var(--color1);
}

.aktualne .title p{
    margin: 0;
    display: inline-block;
    padding-bottom: 5px;
}

.aktualne .infoItem{
    grid-column: span 12;
    font-weight: 300;
    margin: 0 calc(1vw + 10px);
    padding: 22px 0;
    border-bottom: 1px solid rgb(230, 230, 230);
    align-self: start;
}

.aktualne .infoItem.extend{
    grid-row: span 5;
}

.aktualne .infoItem.extend .label{
    font-weight: 900;
}

.aktualne .infoItem .ikona{
    height: 1.2em;
    vertical-align: middle;
    padding-right: 3px;
}

.aktualne .infoItem .label{
    text-transform: uppercase;
    grid-column: span 7;
    align-self: center;
    font-size: 1.1em;
    font-weight: 500;
}

.aktualne .infoItem .data{
    grid-column: span 5;
    font-weight: 700;
    justify-self: right;
    align-self: center;
    font-size: 1.8em;
    color: var(--textColor3);
}

.aktualne .infoItem .ikonaChart{
    height: 0.8em;
    vertical-align: middle;
    padding-left: 3px;
}

.aktualne .infoItem .ikonaTlak{
    height: 0.6em;
    vertical-align: middle;
}

.aktualne .infoItem:nth-last-child(2){
    border-bottom: 0;
}

.aktualne .infoItem .maximum{
    grid-column: 7/13;
    justify-self: right;
    font-size: 0.75em;
    color: var(--textColor3);
    font-weight: 100;
    margin-top: 2px;
}

.aktualne .infoItem .minimum{
    grid-column: 1/7;
    font-size: 0.75em;
    color: var(--textColor3);
    font-weight: 100;
    margin-top: 2px;
}

.aktualne .infoItem.small .label{
    grid-column: span 6;
}

.aktualne .infoItem.small .data{
    grid-column: span 6;
}

.aktualne .infoItem .label.clickable{
    cursor: pointer;
}

/*        SUHRN              */
.aktualne .infoSuhrn{
    grid-column: span 12;
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 40px;
    text-align: left;
}

.rokSelect{
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    background: url("../assets/ikony/black/arrow.png") no-repeat right 0 center / 0.5em;
    cursor: pointer;
    padding-right: 0.8em;
    font-size: 0.8em;
    text-align: right;
    color: var(--textColor3);
}

.rokSelect option{
    font-size: 0.8em;
    font-weight: 500;
    text-align: center;
    color: var(--textColor3);
}

.rokSelect:focus {
	outline: none;
}

.rokSelect::-ms-expand {
	display: none;
}


/*          GRAFY           */
.skrytyGraf{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 12;
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s;
    height: calc(150px + 15vh);
}

.skrytyGraf.extend{
    max-height: calc(150px + 15vh);
}

.highChartsGraf{
    height: calc(150px + 15vh);
    width: 100%;
    align-self: end;
    margin-bottom: -15px;
}


/*          SLNKO MESIAC          */


.aktualne .infoItem.slnkoMesiac{
    height: 100%;
    padding: 0;
}

.aktualne .infoItem .slnkoMesiacData.right{
    justify-self: right;
}

.aktualne .infoItem .slnkoMesiacData{
    grid-column: span 6;
    grid-row: span 2;
    font-weight: 700;
    font-size: 1.8em;
    color: var(--textColor3);
    justify-self: left;
    /*display: none;*/
}

.aktualne .infoItem .slnkoMesiacData .popis{
    font-size: 0.5em;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 500;

}

.aktualne .infoItem .slnkoMesiacData img{
    height: 1.5em;
    vertical-align: middle;
    margin-right: 5px;
}


/*            UPDATED             */

.aktualne .update{
    grid-column: span 12;
    justify-self: center;
    color: var(--textColor3);
    font-size: 0.85em;
    font-weight: 100;
    margin-top: 10px;
}

.aktualne .update .ikona{
    height: 1em;
    vertical-align:middle;
}



@media screen and (min-width: 620px) {
    .aktualne .infoItem{
        grid-column: span 6;
        padding: 25px 0;
    }

    .aktualne .infoItem .minimum,
    .aktualne .infoItem .maximum{
        font-weight: 300;
    }

    .aktualne .infoItem:nth-last-child(3):nth-child(even){
        border-bottom: 0;
    }

    .aktualne .infoSuhrn{
        margin: 10px 0;
    }

    .aktualne .infoItem.suhrn{
        grid-column: span 12;
    }

    .aktualne .infoItem.suhrn .label{
        grid-column: span 6;
        grid-row: 1;
        margin-bottom: 10px;
    }

    .aktualne .infoItem.suhrn .infoSuhrn{
        grid-column: span 6;
        grid-row: 2;
        margin: 0;
    }

    .aktualne .infoItem.suhrn .minimum{
        display: none;
    }

    .aktualne .infoItem.suhrn .maximum{
        display: none;
    }

    .aktualne .infoItem .slnkoMesiacData{
        grid-column: span 3;
        display: block;
        justify-self: right;
    }

}

</style>