<template>
  <div class="headerAktualne grid">
    <div class="headerLogo">
        <div class="headerLogoTitle">MeteoBB</div>
    </div>
    <div class="loaderHeader">
        <div class="loaderItem">Administrácia</div>
    </div>
</div>
</template>

<style>
    .headerAktualne{
        font-size: 2.5vh;
        transform: translateZ(0.5px) scale(0.75);
        transform-origin: top;
    }

    @media screen and (max-height: 375px) {
        .headerAktualne{
            font-size: 9px;
        }
    }

    .headerAktualne .data{
        grid-column: span 12;
        justify-self: center;
    }

    
    .loaderHeader{
        font-size: 0.8em;
        grid-column: span 12;
        display: flex;
        justify-content: center;
        margin-top: 5vh;
    }

    .loaderHeader img{
        height: 1.5em;
        align-self: center;
        margin: 3px;
    }

    .loaderHeader .loaderItem{
        align-self: center;
        margin: 3px;
        font-size: 1.5em;
        font-weight: 700;
    }


    .headerLogo{
        grid-column: span 12;
        justify-self: center;
        padding-top: 0.1em;
        color: white;
        text-align: center;
        align-self: end;
    }

    .headerLogoTitle{
        font-size: 3.5em;
        font-weight: 900;
        cursor: pointer;
    }


    @media (min-aspect-ratio: 5/4) {
        .loaderHeader {
            grid-column: span 4;
            margin-top: 0;
            justify-self: center;
        }

        .headerAktualne{
            height: 100%;
        }

        .headerLogo{
            grid-column: span 6;
            justify-self: end;
            align-self: center;
            text-align: center;
            margin-right: calc(2vw + 20px);
        }

        .headerAktualne .data{
            grid-column: span 6;
            justify-self: start;
            align-self: center;
            margin-left: calc(2vw + 20px);
        }

        .headerLogoTitle{
            font-size: 4.5em;
        }

    }
</style>